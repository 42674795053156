/* 
    .index3-service-con {
        display: flex;
        overflow: hidden; 
        -webkit-overflow-scrolling: touch;
        padding: 20px;
        width: 100% !important;
    } */
    .service-carousel-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5px; /* Add padding to create space between items */
    }    
    .service-type-boxxx {
        flex: 0 0 auto;
        /* width: 110%; */
        /* margin-right: 40px !important;  */
        background-color: #156c5f;
        border-radius: 10px !important;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;    
    }

    .service-type-boxxx h4{
        color: white;
    }
    
    
/* Style the modal (background) */
.lightbox {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 60px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  }
  
  /* Modal Content (image) */
  .lightbox-content {
    position: relative;
    margin: auto;
    padding: 0;
    width: 80%;
    max-width: 1200px;
  }
  
  /* Add Animation */
  .lightbox-content {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }
  
  @-webkit-keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }
  
  @keyframes zoom {
    from {transform:scale(0.1)}
    to {transform:scale(1)}
  }
  
  /* The Close Button */
  .close {
    position: absolute;
    top: 0px;
    right: 35px;
    color: #156c5f !important;
    font-size: 40px;
    font-weight: bold;
    /* background-color: #298d9c !important; */
    transition: 0.3s;
    /* border-radius: 50%; */
    /* padding: 10px; */
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  


