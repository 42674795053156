.custom-dot {
    display: inline-block;
    margin: 0px 5px;
    cursor: pointer;
    /* padding: 5px; */
    /* margin-top: 10px !important; */
  }
  
  .custom-dot span {
    width: 16px;
    height: 16px;
    display: block;
    background-color: gray;
    border-radius: 50%;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  
  .custom-dot--active span {
    background-color: #298d9c;
  }
  